@import url('https://fonts.googleapis.com/css2?family=Allison&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.heading{
  color:wheat;
  text-align: center;
  font-size: 2rem;
  font-family: 'Jost', sans-serif;
}